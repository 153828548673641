@import "~normalize.css/normalize.css";
@import "blueprintjs-styles.css";
@import "~@ag-grid-community/core/dist/styles/ag-grid.css";
@import "~@ag-grid-community/core/dist/styles/ag-theme-balham-dark.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #293742 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header.header0.home-page-wrapper > div.home-page {
  max-width: 1800px;
}

.ant-modal-header {
  border-bottom: 0 !important;
}

.ant-modal-content,
.ant-modal-content .ant-modal-header,
.ant-layout {
  background-color: #293742 !important;
}

.ant-modal-content .ant-statistic-title,
.ant-modal-content .ant-progress-text,
.ant-modal-content .ant-modal-close-x,
.ant-modal-content .ant-modal-title {
  color: white !important;
}

.ant-progress-circle-trail {
  stroke: #00192b !important;
}
